const {
  PUBLIC_URL,
  REACT_APP_API_BASE_URL,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_NAMESPACE,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_ZENDESK_KEY,
  REACT_APP_MIXPANEL_TOKEN,
  REACT_APP_GOOGLE_ANALYTICS_ID,
  REACT_APP_ENV,
  REACT_APP_HOTJAR_ID,
  REACT_APP_DATADOG_APPLICATION_ID,
  REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_SITE,
  REACT_APP_DATADOG_SERVICE,
  REACT_APP_VERSION,
  REACT_APP_HARNESS_SDK_KEY,
  REACT_APP_KEEPER_API_BASE_URL,
  REACT_APP_CUSTOM_VERIFIER_PERMISSION_ENDPOINT,
} = process.env;

interface AppConfig {
  auth0: {
    domain: string;
    clientId: string;
    audience: string;
    namespace: string;
  };
  apiClientOptions: {
    baseURL: string;
    timeout: number;
  };
  keeperApiClientOptions: {
    baseURL: string;
    timeout: number;
  };
  permissionsApiClientOptions: {
    baseURL: string;
    timeout: number;
  };
  zendesk: {
    key: string;
  };
  mixpanel: {
    token: string;
  };
  google_analytics: {
    id: string;
    debug: boolean;
  };
  hotjar: {
    id: string;
  };
  sentry: {
    dns: string;
  };
  env: string;
  version: string;
  datadog: {
    applicationId: string;
    clientToken: string;
    site: string;
    service: string;
  };
  harness: {
    apiKey: string;
  };
  isDevEnvironment: boolean;
}

const Config: AppConfig = {
  auth0: {
    domain: String(REACT_APP_AUTH0_DOMAIN || ""),
    clientId: String(REACT_APP_AUTH0_CLIENT_ID || ""),
    audience: String(REACT_APP_AUTH0_AUDIENCE || ""),
    namespace: String(REACT_APP_AUTH0_NAMESPACE || ""),
  },
  apiClientOptions: {
    baseURL: String(REACT_APP_API_BASE_URL || ""),
    timeout: 60000,
  },
  keeperApiClientOptions: {
    baseURL: String(REACT_APP_KEEPER_API_BASE_URL || ""),
    timeout: 60000,
  },
  permissionsApiClientOptions: {
    baseURL: String(
      REACT_APP_CUSTOM_VERIFIER_PERMISSION_ENDPOINT || PUBLIC_URL || ""
    ),
    timeout: 60000,
  },
  zendesk: {
    key: String(REACT_APP_ZENDESK_KEY || ""),
  },
  mixpanel: {
    token: String(REACT_APP_MIXPANEL_TOKEN || ""),
  },
  google_analytics: {
    id: String(REACT_APP_GOOGLE_ANALYTICS_ID || ""),
    debug: REACT_APP_ENV === "development",
  },
  hotjar: {
    id: String(REACT_APP_HOTJAR_ID || ""),
  },
  sentry: {
    dns: String(process.env.REACT_APP_SENTRY_DNS || ""),
  },
  env: String(REACT_APP_ENV || ""),
  version: String(REACT_APP_VERSION || ""),
  datadog: {
    applicationId: String(REACT_APP_DATADOG_APPLICATION_ID || ""),
    clientToken: String(REACT_APP_DATADOG_CLIENT_TOKEN || ""),
    site: String(REACT_APP_DATADOG_SITE || ""),
    service: String(REACT_APP_DATADOG_SERVICE || ""),
  },
  harness: {
    apiKey: String(REACT_APP_HARNESS_SDK_KEY || ""),
  },
  isDevEnvironment: REACT_APP_ENV === "development",
};

export default Config;
