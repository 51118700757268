import { registerApp } from "@bhubai/hub-shared";
import { registerApplication, start } from "single-spa";

import Config from "@config/index";

import reportWebVitals from "./reportWebVitals";

fetch("/applications.json").then((response) => {
  response.json().then((applications) => {
    applications.forEach(
      (app: { name: string; enabled: boolean; environments: string[] }) => {
        if (app.enabled && app.environments.includes(Config.env)) {
          registerApp(app.name);
        }
      }
    );
  });
});

registerApplication(
  "root",
  () => import("./root.app"),
  () => true
);

start();

reportWebVitals();
